import React from "react";
import PropTypes from "prop-types";
import Typed from "react-typed";
import Particles from "react-particles-js";
import Button from "@ui/button";
import Image from "@ui/image";
import { ImageType, HeadingType, ButtonType } from "@utils/types";

const HeroArea = ({ data, color,bgColor, id  }) => {
    return (
        <section
            id={id}
            className="slider-style-5 rn-section-gap pb--110 align-items-center with-particles"
        >
            <Particles
                className="particles"
                params={{
                    particles: {
                        number: {
                            value: 20,
                            density: {
                                enable: true,
                                value_area: 200,
                            },
                        },
                        color: {
                            value: [color],
                        },
                        shape: {
                            type: "circle",
                            stroke: {
                                width: 0,
                                color: "#000000",
                            },
                            polygon: {
                                nb_sides: 4,
                            },
                            // image: {
                            //     src: "img/github.svg",
                            //     width: 100,
                            //     height: 100,
                            // },
                        },
                        opacity: {
                            value: 0.8,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false,
                            },
                        },
                        size: {
                            value: 3,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 40,
                                size_min: 0.1,
                                sync: false,
                            },
                        },
                        line_linked: {
                            enable: true,
                            distance: 125,
                            color: "#ffffff",
                            opacity: 0.4,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 0.5,
                            direction: "none",
                            random: false,
                            straight: false,
                            out_mode: "out",
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200,
                            },
                        },
                    },
                    interactivity: {
                        detect_on: "canvas",
                        events: {
                            onhover: {
                                enable: true,
                                mode: "repulse",
                            },
                            onclick: {
                                enable: true,
                                mode: "push",
                            },
                            resize: true,
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 1,
                                },
                            },
                            bubble: {
                                distance: 800,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3,
                            },
                            repulse: {
                                distance: 100,
                            },
                            push: {
                                particles_nb: 4,
                            },
                            remove: {
                                particles_nb: 2,
                            },
                        },
                    },
                    retina_detect: true,
                    config_demo: {
                        hide_card: false,
                        background_color: "#b61924",
                        background_image: "",
                        background_position: "50% 50%",
                        background_repeat: "no-repeat",
                        background_size: "cover",
                    },
                }}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="banner-inner">
                            {/*data?.images?.[0]?.src && (
                                <div className="thumbnail">

                                    <Image
                                        src={data.images[0].src}
                                        alt={
                                            data.images[0]?.alt ||
                                            "Ryan Kolk"
                                        }
                                        id="border"
                                        className=""
                                    />
                                </div>
                            )*/}
                            <div className="thumbnail" style={{pointerEvents: "none",maxWidth:"500px",width:"75vw"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 96" width="100%">
                              	<path id="duplicate" stroke="" className="cls-1" d="M61.33,5h-28A28.35,28.35,0,0,0,5,33.35H5A28.35,28.35,0,0,0,33.35,61.69h0L5,90"></path>
                              	<path id="duplicate1" className="cls-2" d="M33,33.68h0A28.35,28.35,0,0,1,61.33,62h0V90"></path>
                              	<path id="duplicate3" className="cls-1" d="M146.33,90.37h0a42.48,42.48,0,0,0-42.45-42.52h0A42.48,42.48,0,0,0,146.33,5.33h0"></path>
                              	<polyline id="duplicate2" className="cls-2" points="90.33 90 90.33 47.85 90.33 5"></polyline>
                              </svg>
                            </div>

                            {data?.headings?.[0] && (
                                <h1>{data.headings[0]?.content}</h1>
                            )}

                            <span className="cd-headline clip is-full-width">
                                {data?.headings?.[1] && (
                                    <span>{data.headings[1]?.content}</span>
                                )}{" "}
                                {data?.animated_texts && (
                                    <Typed
                                        className="animated-text"
                                        strings={data.animated_texts}
                                        typeSpeed={40}
                                        backSpeed={50}
                                        backDelay={1}
                                        loop
                                        smartBackspace
                                    />
                                )}
                            </span>

                            <div className="button-area">
                                {data?.buttons?.map(({ id, path, content }) => (
                                    <Button key={id} path={path}>
                                        <span>{content}</span>
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

HeroArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        animated_texts: PropTypes.arrayOf(PropTypes.string),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

HeroArea.defaultProps = {
    id: "home",
};

export default HeroArea;
